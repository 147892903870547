import React, { FC, useState, useEffect } from 'react';
import { useRecordContext } from 'react-admin';
//import Chip from '@material-ui/core/Chip';
//import { FieldProps } from '../../types';
//import segments from '../segments/data';
//import { makeStyles } from '@material-ui/core';
import { useDataProvider } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import StatusIcon from '@mui/icons-material/CheckCircle';


import get from 'lodash/get';

interface Props {
    source: string;
    label?: string;
}

const CustomServicesField = (props: Props) => {

    const record = useRecordContext();
    
    return (
        <div>
        {record[props.source].map((service) => (
            service != 'crm' && service != 'fl_opettaja') ? 
                <span key={`service_${service}`} style={{textDecoration:'none',color: '#fff',fontSize:12,padding: '1px 6px',borderRadius: 3,marginRight:3,height: 15,marginLeft:3,background: get(record,'mpass.error') && service === 'mpass' ? 'red': 'rgb(96, 125, 139, .5)'}} >{`${service.replace('opepalvelu','OOP').replace('finnlectura','FL').toUpperCase()}`}</span> : <span/>
        )}
        </div>
    );
};

CustomServicesField.defaultProps = {
    source: "services",
    label: "",
};

export default CustomServicesField;
