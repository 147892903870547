import * as React from 'react';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { Edit, SimpleForm, SimpleShowLayout, List, Datagrid, TextField, TextInput, SearchInput, useTranslate, useEditContext, FilterFormClasses, useListController, ListControllerProps, ListProps, useStore, useRecordContext, useResourceContext, BooleanInput, BooleanField } from 'react-admin';
import CustomUrlBooleanInput from './CustomUrlBooleanInput';
import CustomUrlButton from './CustomUrlButton';
import CustomEmpty from './CustomEmpty';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import CustomSelectInput from './CustomSelectInput';
import MovingIcon from '@mui/icons-material/Moving';
import { Grid, Box, Typography } from '@mui/material';
import CustomUserGroupList from './CustomUserGroupList';
import CustomGroupList from './CustomGroupList';
import user from '../user';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    number,
    email,
    regex,
    choices,
} from 'react-admin';

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey: string;
    storeKey?: string;
}

const GroupEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();

    //const useEdit = useEditContext();
    //console.log('useEdit', record, resource, useEdit);
    //console.log("CustomUserGroupList Edit", record, resource);
    return (
        <Edit
            resource={resource}
            id={record.id}
            /* disable the app title change when shown */
            title=" "
            className='cgl-dg-edit'
        >
        {/* //     <SimpleForm className='cgl-dg-edit-form' toolbar={false}>
        //         <Grid container width={{ xs: '100%' }} spacing={2}>
        //             <Grid item xs={12} md={12}>
        //                 <Typography variant="subtitle1" paragraph>Perustiedot</Typography>
        //                 <Typography variant="body1" paragraph>
        //                     Nimi: {record.group.name}
        //                 </Typography>
        //             </Grid>
        //         </Grid> */}

                <Grid container width={{ xs: '100%' }} spacing={2}>
                    <Grid item xs={12} md={12}>
                        {/* <Typography variant="subtitle1" paragraph>Ryhmät</Typography> */}
                        <CustomUserGroupList disableSyncWithLocation storeKey='customUserGroupList' resource='usergroup' filter={{type:4}} customFilters={['parent_guid','user_guid']} showExtra={['']} hideFields={['']} bulkActionButtons={false}><span /></CustomUserGroupList>
                    </Grid>
                </Grid>
{/* 
        //     </SimpleForm> */}
        </Edit>
        );
    };

const SchoolForm = () => {
    const useEdit = useEditContext();
    const record = useRecordContext();
    console.log('useEdit', record);
    return (
        <RowForm defaultValues={{guid: record.guid, user_guid: record.user_guid, group_guid: record.group_guid}}>
            <TextField source="group.name" />
            <CustomSelectInput source="status" url="extralist/teacher/statuses" label="Tila" validate={[required()]} />
            <BooleanInput source="isadmin" label="Ylläpitäjä" />
            {/* <CustomSelectInput resource="userapplication" source="application_guid" url="extralist/userapplication/applications" label="" validate={[required()]} />
            <BooleanInput resource="userapplication" source="isadmin" label="" />
            <CustomSelectInput resource="userapplication" source="public.userrights" url="extralist/userapplication/userrights" label="" showIfControl="application_guid" showIfValue="13b312b5-367c-4c74-bb77-bdb34df4e721" validate={[required()]} />
            <CustomSelectInput resource="userapplication" source="status" url="extralist/userapplication/statuses" label="" validate={[required()]} /> */}
        </RowForm>
    );
}

const CustomUserSchoolList = (props: Props) => {

    const translate = useTranslate();

    const parent = useEditContext();
    //console.log(parent);
    var filter = {};

    if (props.filter)
        filter = props.filter;

    if (props.customFilters?.includes("user_guid"))
        filter["user_guid"] = parent.record.guid;

    return (
        <List {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<SearchInput source="q" size="small" alwaysOn />] : undefined} empty={<CustomEmpty />} pagination={false}>
            {/* <Datagrid bulkActionButtons={props.bulkActionButtons} className='cgl-dg' expand={<GroupEdit />} expandSingle={true} rowClick="expand">
                {!props.hideFields?.includes("name") && <TextField source="group.name" label={translate('resources.school.name', 2)} sortable={props.sortable} />}
            </Datagrid> */}
            <EditableDatagrid editForm={<SchoolForm />} bulkActionButtons={props.bulkActionButtons} className='cgl-dg' expand={<GroupEdit />} expandSingle={true} rowClick="expand">
                {!props.hideFields?.includes("name") && <TextField source="group.name" label={translate('resources.school.name', 2)} sortable={props.sortable} />}
            </EditableDatagrid>
        </List>
    );
};

CustomUserSchoolList.defaultProps = {
    sortable: true,
    showExtra: [],
    hideFields: [],
    customFilters: [],
    refreshKey: 'refreshAll',
    storeKey: 'customUserSchoolList',
};

export default CustomUserSchoolList;
