import * as React from 'react';
import { useRecordContext, useDataProvider } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import {get,find} from 'lodash';

interface Props {
    source: string;
    label?: string;
    sortable?: boolean;
}

const SubjectListField = (props: Props) => {
   
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    
    const url = "extralist/teacher/roles";
    let params = '';

    const { data,error,isPending,refetch } = useQuery({
        queryKey: [url, 'getAny', params], 
        queryFn: () => dataProvider.getAny(url, params)
    });

    if (!data) return null;

    let foundSubroles = data.data[0];
    let teacherSubroles = get((find(record.group,{type:2}) || record.group[0]),'public.subject',[]);
    let subroles = "";

    if(foundSubroles && teacherSubroles.length > 0){
        subroles = teacherSubroles.map(x => {
           let subrole = find(foundSubroles.subject,{guid:x.toUpperCase()});
           return subrole ? subrole.abbreviation : '-';
        }).join(', ');
    }

    return (
        <div>{subroles}</div>
    );
};

SubjectListField.defaultProps = {
    source: "group",
    label: "",
    sortable: false,
};

export default SubjectListField;
